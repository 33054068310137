'use client';

import Link from 'next/link';
import { SignIn } from '@clerk/nextjs';
import useMixpanel from '../../../hooks/useMixpanel';
import { useEffect } from 'react';
import { SpindlNavLogo } from '../../../../public/icons';

export default function Login() {
  const { pageView } = useMixpanel();

  useEffect(() => {
    pageView(`Login`);
  }, [pageView]);
  return (
    <div className="bg-brand-navy-base flex h-screen flex-col bg-[url('/images/login-background-image.svg')] bg-auto bg-center bg-no-repeat">
      <div className="flex items-center justify-between px-6 pt-6 sm:px-12 sm:pt-8">
        <SpindlNavLogo />
        <div>
          <ul className="flex flex-row text-xs font-normal text-gray-400 sm:text-sm">
            <li className="hover:text-brand-green-base mx-2 cursor-pointer">
              <Link legacyBehavior href="https://www.spindl.xyz/">
                Overview
              </Link>
            </li>
            <li className="hover:text-brand-green-base mx-2 cursor-pointer">
              <Link legacyBehavior href="https://www.spindl.xyz/#blog">
                Blog
              </Link>
            </li>
            <li className="hover:text-brand-green-base mx-2 cursor-pointer">
              <Link legacyBehavior href="https://www.spindl.xyz/#careers">
                Jobs
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-1 items-center justify-center px-6 py-12 lg:px-8">
        <SignIn path="/login" routing="path" signUpUrl="/signup" afterSignInUrl="/dashboard" />
      </div>
    </div>
  );
}
